
.html {
  color: #353535;
}

body {
  font-size: 16px;
  line-height: 1.9;
}

* {
  font-family: 'Avenir Next', 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol' !important;
}

h1 {
  font-size: 40px;
  font-weight: 600;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 20px;
}

h2, h3, b {
  font-weight: 600;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.clear-both {
  clear: both;
}

.desktop-only {
  display: unset;
}

.mobile-only {
  display: none;
}

.container {
  width: 1000px;
  margin: 0px auto;
  padding: 0px 20px;
  a {
    cursor: pointer;
    text-decoration: none;
  }
  img {
    max-width: 100%;
  }
}

@media (max-width: 960px) {
  .container {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .desktop-only {
    display: none;
  }
  .mobile-only {
    display: unset;
    margin-left: auto;
    margin-right: auto;
  }
}