.footer {
  border-top: 1px solid #eeeeee;
  text-align: center;
  padding: 30px 0px 0px 0px;
  a {
    color: #444 !important;
    text-decoration: none;
  }

  a:hover {
    color: #0078C5 !important;
  }

  .copyright {
    background-color: #fafafa;
    margin: 0px auto;
    color: #444;
    padding: 50px 0px 50px 0px;
  }

  .footer-wrapper {
    display: flex;
    flex-direction: row;
    margin: 0px auto;
    width: 1000px;
    padding: 20px 0px 60px 0px;

    h3 {
      margin: 10px 0px;
    }
    
    .footer-col {
      flex-grow: 1;
      flex-basis: 0;
      min-width: 0;
      text-align: left;
      padding: 0px 10px;
    }

    .footer-about-col {
      @extend .footer-col;
      flex-grow: 1.5;
    }
  }
}

@media (max-width: 767px) {
  .footer-wrapper {
    display: block !important;
    width: auto !important;
  }
}