
.header {
  padding: 15px 0px 13px 0px;
  position: fixed;
  z-index: 1000;
  background-color: white;
  box-shadow: 0 2px 12px 0 rgba(36,50,66,.075);
  width: 100%;

  .header-inner {
    display: flex;
    align-items: center;

    .logo {
      display: flex;
      align-items: center;
      font-size: 20px;
      font-weight: 600;
      color: #353535;

      img {
        margin-right: 10px;
        height: 24px;
      }
    }

    .header-blank {
      flex-grow: 1;
    }

    .header-links {
      font-size: 16px;
      a {
        margin-left: 30px;
        text-decoration: none;
        color: #606060;
      }
      a:hover {
        color: #0078C5;
      }
      .download {
        padding: 7px 16px;
        border: 1.5px solid #127ac3;
        color: #127ac3;
        border-radius: 4px;
        font-weight: 500;
      }
    }
  }
}


@media (max-width: 767px) {
  .header-links {
    a {
      margin-left: 10px !important;
    }
    .support, .blog {
      display: none !important;
    }
  }
}