@import './bootstrap-reboot.css';
@import './common.scss';
@import './header.scss';
@import './footer.scss';

.text {
  padding-top: 120px;
  padding-bottom: 90px;
  font-size: 16px;

  h2 {
    padding-top: 20px;
  }
}

.text-section {
  @extend .text;
  padding-top: 70px;
}

.gray-bg {
  background-color: #fafafa;
}

.support-item {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  margin: 25px 0px;
}

.not-found {
  text-align: center;
  padding: 220px 0px 190px 0px;

  .not-found-img {
    height: 110px;
  }

  .not-found-subtitle {
    color: #777;
    margin: 40px 0px 35px 0px;
    font-size: 32px;
  }

  .not-found-description {
    color: #777;
    font-size: 20px;
  }
}